.footer_container {
  width: 100%;
  border-top: 2px solid #ffffff60;
  text-align: center;
}

.footer_content h6 {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* responsiveness */

@media only screen and (max-width: 700px) {
  .footer_content h6 {
    padding: 20px 40px;
  }

  .footer_content h6 {
    font-size: 9px;
  }
}
