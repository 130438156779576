.App {
  margin: 0;
  padding: 0;
  background-color: #cfd8d7;
}

.text-color {
  color: #247ba0;
}

.space-border {
  width: 60px;
  border-bottom: 2px solid #247ba0;
}

.backdrop {
  height: 60vh;
}

.backdrop-img {
  border-bottom: 2px solid #ffffff60;
}

.backdrop-img {
  height: 60vh;
  width: 100%;
  object-fit: cover;
}

/* -------- responsiveness -------- */
@media only screen and (max-width: 700px) {
  .space-border {
    margin: 0 auto;
  }
}
