.form {
  max-width: 400px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
textarea {
  width: 80%;
  max-width: 80%;
  /* margin: 10px 0; */
  padding: 10px;
  border-radius: 0.4rem;
  border: 0.2px solid #247ba0;
}

textarea:focus,
input:focus {
  outline: none;
  border: 2px solid #247ba0;
}

/* ----- submit button ------ */
button {
  height: 40px;
  width: 100px;
  border-radius: 15px;
  outline: none;
  border: 2px solid #247ba060;
  background-color: #247ba060;
  font-size: 16px;
  font-weight: 600;
  color: #185975;
}

button:hover {
  cursor: pointer;
  border: 2px solid #247ba0;
  background-color: #247ba0;
  color: #fff;
  text-decoration: underline;
}

/* ----- validations ------ */
.errorValidation {
  padding-top: 5px;
  color: red;
}

.successValidation {
  padding-top: 10px;
  color: green;
  text-align: center;
}

/* responsiveness */

@media only screen and (max-width: 700px) {
  .form {
    max-width: 100%;
  }
}
