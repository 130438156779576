.App {
  margin: 0;
  padding: 0;
  background-color: #cfd8d7;
}

.text-color {
  color: #247ba0;
}

.space-border {
  width: 60px;
  border-bottom: 2px solid #247ba0;
}

.backdrop {
  height: 60vh;
}

.backdrop-img {
  border-bottom: 2px solid #ffffff60;
}

.backdrop-img {
  height: 60vh;
  width: 100%;
  object-fit: cover;
}

/* -------- responsiveness -------- */
@media only screen and (max-width: 700px) {
  .space-border {
    margin: 0 auto;
  }
}

/* Contact details section */
.get-in-touch {
  position: relative;
  border-top: 2px solid #ffffff60;
  padding: 20px;
  width: 100%;
  display: flex;
}

.form-container {
  width: 500px;
  margin: 0 auto;
  text-align: center;
}

.form-heading {
  text-align: center;
}

.form-border {
  margin: 0 auto;
}

.contact-details {
  margin: 10px 0 0;
}

.contact-details li {
  list-style-type: none;
  margin: 10px;
}

.contact-details p:hover {
  cursor: pointer;
  color: #fff;
}

/* navbar links */
.contact-details p a {
  color: #247ba0;
  text-decoration: none;
}

.contact-details p a:hover {
  border-bottom: 1px solid #247ba0;
  cursor: pointer;
}

/* responsiveness */
@media only screen and (max-width: 700px) {
  .get-in-touch {
    margin: 0;
    width: 100%;
    padding-top: 20px;
    position: relative;
  }
}

.details-container {
  position: relative;
  display: flex;
  align-items: center;
}

.details-content {
  padding: 50px 20px;
  display: flex;
  max-width: 1200px;
  min-width: 360px;
  margin: auto;
}

.details-content p {
  font-size: 18px;
}

/* headings */
.secondary-heading {
  font-size: 28px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.sub-heading {
  color: #247ba0;
}

/* list levels */
.list-level-1 {
  margin-left: 25px;
}

/* blocks of content */
.who-i-am {
  margin-right: 10px;
  border-right: 2px solid #247ba0;
}

.who-i-am,
.what-i-do {
  min-width: 300px;
  max-width: 800px;
}

.who-i-am {
  padding: 5px 10px 5px 5px;
}
.what-i-do {
  padding: 5px;
}

/* Cover image */
.cover-img-container {
  display: flex;
  align-items: center;
}
.cover-img {
  max-height: 550px;
  width: auto;
  margin: 0 auto;
}

/* address link */
.address {
  color: #247ba0;
  outline: none;
}

/* responsiveness */
@media only screen and (max-width: 700px) {
  .details-container {
    height: auto;
  }
  .details-content {
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .who-i-am {
    border-right: 0px;
    border-bottom: 2px solid #247ba0;
  }

  .who-i-am,
  .what-i-do,
  .get-in-touch {
    margin: 0;
    width: 100%;
    padding-top: 20px;
    position: relative;
  }

  .who-i-am,
  .what-i-do {
    margin-bottom: 20px;
  }

  /* Cover image */
  .cover-img-container {
    margin: 30px auto;
  }
  .cover-img {
    max-height: 400px;
    width: auto;
    margin: 0 auto;
  }

  /* headings */
  .secondary-heading {
    text-align: center;
  }
}

.form {
  max-width: 400px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
textarea {
  width: 80%;
  max-width: 80%;
  /* margin: 10px 0; */
  padding: 10px;
  border-radius: 0.4rem;
  border: 0.2px solid #247ba0;
}

textarea:focus,
input:focus {
  outline: none;
  border: 2px solid #247ba0;
}

/* ----- submit button ------ */
button {
  height: 40px;
  width: 100px;
  border-radius: 15px;
  outline: none;
  border: 2px solid #247ba060;
  background-color: #247ba060;
  font-size: 16px;
  font-weight: 600;
  color: #185975;
}

button:hover {
  cursor: pointer;
  border: 2px solid #247ba0;
  background-color: #247ba0;
  color: #fff;
  text-decoration: underline;
}

/* ----- validations ------ */
.errorValidation {
  padding-top: 5px;
  color: red;
}

.successValidation {
  padding-top: 10px;
  color: green;
  text-align: center;
}

/* responsiveness */

@media only screen and (max-width: 700px) {
  .form {
    max-width: 100%;
  }
}

.spacer {
  position: absolute;
  height: 130px;
  bottom: 100%;
}

.footer_container {
  width: 100%;
  border-top: 2px solid #ffffff60;
  text-align: center;
}

.footer_content h6 {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* responsiveness */

@media only screen and (max-width: 700px) {
  .footer_content h6 {
    padding: 20px 40px;
  }

  .footer_content h6 {
    font-size: 9px;
  }
}

* {
  margin: 0;
  box-sizing: border-box;
}

/* -------- navbar -------- */

.nav {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
}

.menu {
  border-bottom: 2px solid transparent;
}

.mobile-menu {
  z-index: 2;
}

.nav-background {
  background-color: #247ba0a0;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-bottom: 2px solid #fff;
  -webkit-animation: fadeBackgroundIn 1s;
          animation: fadeBackgroundIn 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes fadeBackgroundIn {
  from {
    background-color: transparent;
  }
  to {
    background-color: #247ba0a0;
  }
}

@keyframes fadeBackgroundIn {
  from {
    background-color: transparent;
  }
  to {
    background-color: #247ba0a0;
  }
}

.nav-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 1200px;
  min-width: 360px;
  padding: 20px;
  margin: 0 auto;
}

/* navbar title */
.nav-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.nav-title {
  text-align: center;
  width: 100%;
  border-bottom: 2px solid #fff;
}

.nav-title-container h1 {
  font-size: 32px;
  font-weight: 400;
}
.nav-title-container h2 {
  font-size: 20px;
  font-weight: 100;
}

/* navbar links */
.nav-list {
  display: flex;
  list-style-type: none;
}

.nav-link {
  display: block;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0 20px;
  transition: 0.5s ease-in-out;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  width: 100%;
  padding: 10px;
}

.nav-link:hover {
  border-bottom: 2px solid #247ba0;
  text-shadow: 0px 0px 1px;
  cursor: pointer;
  color: #247ba0;
}

/* ----- responsiveness ------  */
@media only screen and (max-width: 700px) {
  /* --------------links menu ------------ */
  .menu {
    display: flex;
    align-items: center;
    width: 80%;
    height: 100vh;
    background-color: #247ba0a0;
    border-left: 2px solid #fff;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: 1rem 0 0 0;
    position: absolute;
    top: 0;
    transition: ease-in-out 0.5s;
  }

  .mobile-hidden {
    display: none;
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 300px;
  }

  .nav-list li {
    width: 100%;
    border-bottom: 2px solid #fff;
  }

  .nav-link:hover {
    border-bottom: 2px solid #fff;
    text-shadow: 0px 0px 1px;
    cursor: pointer;
    color: #fff;
  }

  /* --------------burger ------------ */
  .burger-container {
    height: 20px;
    position: fixed;
    right: 2rem;
    top: 4rem;
    z-index: 3;
  }

  .burger-menu {
    height: 100%;
    width: 2.1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
  }

  .burger-bar {
    width: 2rem;
    height: 0.2rem;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .menu {
    width: 80%;
    height: 100vh;
    background-color: #247ba0a0;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: 1rem 0 0 0;
    position: fixed;
    top: 0;
    transition: ease-in-out 0.5s;
  }

  /* animations */
  /* clicked */
  .burger-bar.clicked:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(0.2rem, 0.2rem);
            transform: rotate(45deg) translate(0.2rem, 0.2rem);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(2) {
    display: none;
  }

  .burger-bar.clicked:nth-child(3) {
    -webkit-transform: rotate(135deg) translate(-0.5em, 0.5em);
            transform: rotate(135deg) translate(-0.5em, 0.5em);
    transition: ease-out 0.5s;
  }

  /* unclicked */
  .burger-bar.unclicked {
    transition: ease-out 0.5s;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }

  /* sliding animation */
  .hidden {
    right: -80%;
  }

  .visible {
    right: 0%;
  }
}

@media only screen and (min-width: 700px) {
  .mobile-menu {
    display: none;
  }
}

.write-up-container {
  position: relative;
  height: 40vh;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #ffffff60;
  border-radius: 0 0 1rem 1rem;
}

.write-up-content {
  padding: 50px 20px;
  max-width: 1200px;
  min-width: 360px;
}

.write-up-content p {
  font-size: 18px;
}

.primary-heading {
  font-size: 32px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.mental-health-link {
  color: #247ba0;
}

.mental-health-link:visited {
  color: #247ba0;
}

/* -------- responsiveness -------- */
@media only screen and (max-width: 700px) {
  .write-up-container {
    height: auto;
  }

  .primary-heading {
    text-align: center;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body:after {
  display: none;
  content: url(images/ocean.jpg);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 10px 0;
}

html {
  scroll-behavior: smooth;
}

