* {
  margin: 0;
  box-sizing: border-box;
}

/* -------- navbar -------- */

.nav {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
}

.menu {
  border-bottom: 2px solid transparent;
}

.mobile-menu {
  z-index: 2;
}

.nav-background {
  background-color: #247ba0a0;
  backdrop-filter: blur(10px);
  border-bottom: 2px solid #fff;
  animation: fadeBackgroundIn 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeBackgroundIn {
  from {
    background-color: transparent;
  }
  to {
    background-color: #247ba0a0;
  }
}

.nav-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 1200px;
  min-width: 360px;
  padding: 20px;
  margin: 0 auto;
}

/* navbar title */
.nav-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.nav-title {
  text-align: center;
  width: 100%;
  border-bottom: 2px solid #fff;
}

.nav-title-container h1 {
  font-size: 32px;
  font-weight: 400;
}
.nav-title-container h2 {
  font-size: 20px;
  font-weight: 100;
}

/* navbar links */
.nav-list {
  display: flex;
  list-style-type: none;
}

.nav-link {
  display: block;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0 20px;
  transition: 0.5s ease-in-out;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  width: 100%;
  padding: 10px;
}

.nav-link:hover {
  border-bottom: 2px solid #247ba0;
  text-shadow: 0px 0px 1px;
  cursor: pointer;
  color: #247ba0;
}

/* ----- responsiveness ------  */
@media only screen and (max-width: 700px) {
  /* --------------links menu ------------ */
  .menu {
    display: flex;
    align-items: center;
    width: 80%;
    height: 100vh;
    background-color: #247ba0a0;
    border-left: 2px solid #fff;
    backdrop-filter: blur(10px);
    border-radius: 1rem 0 0 0;
    position: absolute;
    top: 0;
    transition: ease-in-out 0.5s;
  }

  .mobile-hidden {
    display: none;
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 300px;
  }

  .nav-list li {
    width: 100%;
    border-bottom: 2px solid #fff;
  }

  .nav-link:hover {
    border-bottom: 2px solid #fff;
    text-shadow: 0px 0px 1px;
    cursor: pointer;
    color: #fff;
  }

  /* --------------burger ------------ */
  .burger-container {
    height: 20px;
    position: fixed;
    right: 2rem;
    top: 4rem;
    z-index: 3;
  }

  .burger-menu {
    height: 100%;
    width: 2.1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
  }

  .burger-bar {
    width: 2rem;
    height: 0.2rem;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .menu {
    width: 80%;
    height: 100vh;
    background-color: #247ba0a0;
    backdrop-filter: blur(10px);
    border-radius: 1rem 0 0 0;
    position: fixed;
    top: 0;
    transition: ease-in-out 0.5s;
  }

  /* animations */
  /* clicked */
  .burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0.2rem, 0.2rem);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(2) {
    display: none;
  }

  .burger-bar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-0.5em, 0.5em);
    transition: ease-out 0.5s;
  }

  /* unclicked */
  .burger-bar.unclicked {
    transition: ease-out 0.5s;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }

  /* sliding animation */
  .hidden {
    right: -80%;
  }

  .visible {
    right: 0%;
  }
}

@media only screen and (min-width: 700px) {
  .mobile-menu {
    display: none;
  }
}
