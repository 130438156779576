.write-up-container {
  position: relative;
  height: 40vh;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #ffffff60;
  border-radius: 0 0 1rem 1rem;
}

.write-up-content {
  padding: 50px 20px;
  max-width: 1200px;
  min-width: 360px;
}

.write-up-content p {
  font-size: 18px;
}

.primary-heading {
  font-size: 32px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.mental-health-link {
  color: #247ba0;
}

.mental-health-link:visited {
  color: #247ba0;
}

/* -------- responsiveness -------- */
@media only screen and (max-width: 700px) {
  .write-up-container {
    height: auto;
  }

  .primary-heading {
    text-align: center;
  }
}
