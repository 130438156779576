.details-container {
  position: relative;
  display: flex;
  align-items: center;
}

.details-content {
  padding: 50px 20px;
  display: flex;
  max-width: 1200px;
  min-width: 360px;
  margin: auto;
}

.details-content p {
  font-size: 18px;
}

/* headings */
.secondary-heading {
  font-size: 28px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.sub-heading {
  color: #247ba0;
}

/* list levels */
.list-level-1 {
  margin-left: 25px;
}

/* blocks of content */
.who-i-am {
  margin-right: 10px;
  border-right: 2px solid #247ba0;
}

.who-i-am,
.what-i-do {
  min-width: 300px;
  max-width: 800px;
}

.who-i-am {
  padding: 5px 10px 5px 5px;
}
.what-i-do {
  padding: 5px;
}

/* Cover image */
.cover-img-container {
  display: flex;
  align-items: center;
}
.cover-img {
  max-height: 550px;
  width: auto;
  margin: 0 auto;
}

/* address link */
.address {
  color: #247ba0;
  outline: none;
}

/* responsiveness */
@media only screen and (max-width: 700px) {
  .details-container {
    height: auto;
  }
  .details-content {
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .who-i-am {
    border-right: 0px;
    border-bottom: 2px solid #247ba0;
  }

  .who-i-am,
  .what-i-do,
  .get-in-touch {
    margin: 0;
    width: 100%;
    padding-top: 20px;
    position: relative;
  }

  .who-i-am,
  .what-i-do {
    margin-bottom: 20px;
  }

  /* Cover image */
  .cover-img-container {
    margin: 30px auto;
  }
  .cover-img {
    max-height: 400px;
    width: auto;
    margin: 0 auto;
  }

  /* headings */
  .secondary-heading {
    text-align: center;
  }
}
